"use strict";
exports.__esModule = true;
var $ = require("jquery");
var position = null;
function init() {
    if (!position) {
        var navbar_height = $('nav.navbar').height();
        var profile_height = $('#profile').height();
        var about_height = $('#about').height() + profile_height;
        var news_height = $('#news').height() + about_height;
        position = {
            'about': Math.round(profile_height - (navbar_height * 0.5)),
            'news': Math.round(about_height - (navbar_height * 0.5)),
            'contact': Math.round(news_height - (navbar_height * 0.5))
        };
    }
}
function setLightNavbar() { $('nav.navbar').removeClass('navbar-dark').addClass('navbar-light'); }
function setDarkNavbar() { $('nav.navbar').removeClass('navbar-light').addClass('navbar-dark'); }
function navbarSettings() {
    init();
    if (window.scrollY >= position.contact) {
        setDarkNavbar();
    } // Contact
    else if (window.scrollY >= position.news) {
        setLightNavbar();
    } // News
    else if (window.scrollY >= position.about) {
        setLightNavbar();
    } // About
    else {
        setDarkNavbar();
    } // Profile
}
$(function () {
    navbarSettings();
    window.onscroll = function () { navbarSettings(); };
    window.onresize = function () { navbarSettings(); };
});

// jQuery
const $ = require('jquery');
global.$ = global.jQuery = $;

// Bootstrap
require('bootstrap');

// Common JS
import './scripts/navbar';
import './scripts/flash_messages';
import './scripts/form_contact';

// Common CSS
import './styles/app.scss';

// start the Stimulus application
import './bootstrap';

"use strict";
exports.__esModule = true;
var $ = require("jquery");
$(function () {
    // Manage floating labels
    $("#contact .field-input")
        .each(function () {
        if ($(this).val()) {
            $(this).parent().addClass("has-label");
        }
    })
        .focus(function () {
        $(this).parent().addClass("is-focused has-label");
    })
        .blur(function () {
        var parent = $(this).parent();
        if ($(this).val() === '') {
            parent.removeClass("has-label");
        }
        parent.removeClass("is-focused");
    });
    // Add empty options on required select
    $('#contact select').each(function () {
        $(this).prepend("<option value=''></option>");
        if ($('#contact').attr('data-submit') === "false") {
            $(this).val('').parent().removeClass('has-label');
        }
    });
    // Auto-select subject
    $('#btn_cv').click(function () {
        $('#contact select').val(4).parent().addClass('has-label');
    });
});
